import {
  AccountInfo,
  EventType,
  IPublicClientApplication,
} from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import React, { lazy, useCallback, useEffect, useState } from "react";
import api, { ApiError } from "../api/api";
import { b2cPolicies, protectedResources } from "../authConfig";
import { useCompany } from "../company/useCompany";
import { DisabledResult, useToast } from "acsiss-ui";
import { AuthContext } from "./AuthContext";
import { Center, Fade } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { Company } from "../api/types/Company";
import { resolveShouldShowTermsWizard } from "../AppRoute";
import { FullPageLoadingSpinner } from "../components/full-page-loading-spinner/FullPageLoadingSpinner";
import { convertUriToAcsissRedirectUri } from "../pages/redirect/redirect-uri-converter";

const InactiveLogoutTimer = lazy(() => import("inactive-logout-timer"));

interface ACSISSAccountClaims {
  family_name: string;
  given_name: string;
  extension_Mobile: string;
}

export interface ACSISSAccount extends AccountInfo {
  idTokenClaims?: AccountInfo["idTokenClaims"] & ACSISSAccountClaims;
}

export const getUserFromAccount = (
  account: ACSISSAccount,
  role: UserRole,
  currentUserData?: User
): User => {
  const { family_name, given_name, extension_Mobile } =
    account.idTokenClaims ?? {};

  return {
    firstName: given_name || "",
    lastName: family_name || "",
    email: account.username || currentUserData?.email || "",
    id: account.localAccountId,
    mobile: extension_Mobile || currentUserData?.mobile || "",
    role,
  };
};

const getTokenFromAccount = (
  account: AccountInfo,
  instance: IPublicClientApplication
) =>
  instance
    .acquireTokenSilent({
      account,
      scopes: protectedResources.scopes,
    })
    .then((token) => token.accessToken);

export const getExistingUserDetails = async (
  userId: string,
  token: string
): Promise<{
  userRole: UserRole;
  isNewUser: boolean;
  companyId?: string;
  isEnabled?: boolean;
  firstName?: string;
  lastName?: string;
  email?: string;
  mobileNumber?: string;
  enabledWhenBlockingNewSignUps?: boolean;
  currentWizardSessionId?: string;
  currentWizardName?: string;
}> => {
  try {
    const userPerApi = await api.get(`/api/v1/user/${userId}`, token);
    return {
      userRole: userPerApi.role as UserRole,
      isNewUser: false,
      companyId: userPerApi.companyId,
      isEnabled: userPerApi.isEnabled,
      firstName: userPerApi.firstName,
      lastName: userPerApi.lastName,
      email: userPerApi.email,
      mobileNumber: userPerApi.mobile,
      enabledWhenBlockingNewSignUps: userPerApi.enabledWhenBlockingNewSignUps,
      currentWizardSessionId: userPerApi.currentWizardSessionId,
      currentWizardName: userPerApi.currentWizardName,
    };
  } catch (e) {
    if (e instanceof ApiError) {
      if (e.statusCode === "NotFound" || e.statusCode == 404) {
        return {
          userRole: "Administrator",
          isNewUser: true,
        };
      }
    }

    throw e;
  }
};

export const AuthProvider = ({ children }: { children: JSX.Element }) => {
  const [user, setUser] = useState<User | undefined>(undefined);
  const { setCompany } = useCompany();

  /**
   * useMsal is hook that returns the PublicClientApplication instance,
   * an array of all accounts currently signed in and an inProgress value
   * that tells you what msal is currently doing. For more, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
   */
  const { instance: msalInstance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const { successToast, errorToast } = useToast();
  const [isUserDisabled, setIsUserDisabled] = useState(false);
  const [
    isUserNotEnabledWhenBlockingNewSignUps,
    setUserNotEnabledWhenBlockingNewSignUps,
  ] = useState(false);
  const [isCompanyDisabled, setIsCompanyDisabled] = useState(false);
  const [displayLoadingScreen, setDisplayLoadingScreen] = useState(false);
  const shouldDisableAutoLogin = React.useRef<boolean>(false);
  const navigate = useNavigate();

  let location = useLocation();
  const disableAutoAuthPerRoute = [
    "/company-invitation-to-user",
    "/invitation-to-company-director",
    "/flow",
  ].some((l) => location.pathname.startsWith(l));

  const handleLoadUser = useCallback(async (user: User) => {
    console.debug("handleLoadUser", user);

    const token = await handleGetToken();

    setUser(user);
    const userResult = await api.put(`/api/v1/user/${user.id}`, user, token);
    if (!userResult.data.isEnabled) {
      setIsUserDisabled(true);
    }

    if (
      !userResult.data.enabledWhenBlockingNewSignUps &&
      window.appConfig.blockNewSignUpsFeatureFlag
    ) {
      setUserNotEnabledWhenBlockingNewSignUps(true);
    }

    const companyResult = await api.get(
      `/api/v1/company/${userResult.data.companyId}`,
      token
    );
    setCompany(companyResult);
    if (!companyResult.isEnabled) {
      setIsCompanyDisabled(true);
    }
  }, []);

  const handleDisableAutoLogin = useCallback(() => {
    shouldDisableAutoLogin.current = true;
  }, []);

  useEffect(
    // This hook loads the user when b2c has authenticated them
    function handleAutoLoadUserOnLogin() {
      // for flows when we log in by popup we disable auto login
      // because we need to ensure those flows await the user being saved in the api
      // before proceeding
      if (shouldDisableAutoLogin.current) {
        return;
      }

      if (disableAutoAuthPerRoute) {
        return;
      }

      // accounts were being returned even when isAuthenticated is false
      // so let's stop here, so we don't upsert the user twice
      if (!isAuthenticated) {
        return;
      }

      const accounts = msalInstance.getAllAccounts();
      if (accounts.length) {
        // accounts[0] is the account that was used to sign in. We can use this to get the token to make API calls
        (async () => {
          try {
            const account = accounts[0];

            // get the token silently
            const token = await getTokenFromAccount(account, msalInstance);

            // make an api to get the user
            const {
              userRole,
              isNewUser,
              isEnabled: isExistingUserEnabled,
              companyId: existingUserCompanyId,
              firstName: existingUserFirstName,
              lastName: existingUserLastName,
              email: existingUserEmail,
              mobileNumber: existingUserMobile,
              enabledWhenBlockingNewSignUps: isEnabledWhenBlockingNewSignUps,
              currentWizardSessionId,
              currentWizardName,
            } = await getExistingUserDetails(account.localAccountId, token);

            const userFromAccount = getUserFromAccount(
              account as ACSISSAccount,
              userRole
            );

            const user: User = {
              ...userFromAccount,
              firstName: existingUserFirstName || userFromAccount.firstName,
              lastName: existingUserLastName || userFromAccount.lastName,
              email: existingUserEmail || userFromAccount.email,
              mobile: existingUserMobile || userFromAccount.mobile,
              currentWizardName,
              currentWizardSessionId,
            };

            console.debug("user", user);
            setUser(user);

            let isEnabled = isExistingUserEnabled;
            let companyId = existingUserCompanyId;
            let isUserEnabledWhenBlockingNewSignUps =
              isEnabledWhenBlockingNewSignUps;

            if (isNewUser) {
              const userResult = await api.put(
                `/api/v1/user/${account.localAccountId}`,
                user,
                token
              );

              user.currentWizardName = userResult.data.currentWizardName;
              user.currentWizardSessionId =
                userResult.data.currentWizardSessionId;

              isEnabled = userResult.data.isEnabled;
              companyId = userResult.data.companyId;
              isUserEnabledWhenBlockingNewSignUps =
                userResult.data.enabledWhenBlockingNewSignUps;

              console.debug("user", user);
              setUser(user);
            }

            if (!isEnabled) {
              setIsUserDisabled(true);
            }

            if (
              !isUserEnabledWhenBlockingNewSignUps &&
              window.appConfig.blockNewSignUpsFeatureFlag
            ) {
              setUserNotEnabledWhenBlockingNewSignUps(true);
            }

            const companyResult = (await api.get(
              `/api/v1/company/${companyId}`,
              token
            )) as Company & { isEnabled: true };

            setCompany(companyResult);
            if (!companyResult.isEnabled) {
              setIsCompanyDisabled(true);
            }

            if (resolveShouldShowTermsWizard(companyResult, user)) {
              navigate("/accept-terms", { replace: true });
            }
          } catch (e: any) {
            if (e.name === "InteractionRequiredAuthError") {
              msalInstance.logoutRedirect();
            }
          }
        })();
      } else {
        setUser(undefined);
      }
    },
    [isAuthenticated, disableAutoAuthPerRoute]
  );

  /**
   * Using the event API, you can register an event callback that will do something when an event is emitted.
   * When registering an event callback in a React component you will need to make sure you do 2 things.
   * 1) The callback is registered only once
   * 2) The callback is unregistered before the component unmounts.
   * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/events.md
   */
  useEffect(function registerCallbackToLogoutPerProfileUpdate() {
    const callbackId = msalInstance.addEventCallback((event: any) => {
      if (
        event.eventType === EventType.LOGIN_SUCCESS ||
        event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
      ) {
        if (event?.payload) {
          /**
           * We need to reject id tokens that were not issued with the default sign-in policy.
           * "acr" claim in the token tells us what policy is used (NOTE: for new policies (v2.0), use "tfp" instead of "acr").
           * To learn more about B2C tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
           */
          if (
            event.payload.idTokenClaims["tfp"] ===
            b2cPolicies.names.forgotPassword
          ) {
            window.alert(
              "Password has been reset successfully. \nPlease sign-in with your new password."
            );
            return msalInstance.logout();
          } else if (
            event.payload.idTokenClaims["tfp"] === b2cPolicies.names.editProfile
          ) {
            window.alert(
              "Profile has been edited successfully. \nPlease sign-in again."
            );
            return msalInstance.logout();
          }
        }
      } else if (event.eventType == EventType.LOGIN_FAILURE) {
        const isUserHasForgottenPassword =
          event.error.errorMessage.includes("AADB2C90118");

        if (isUserHasForgottenPassword) {
          // This occurs when the user clicks the "Forgot password" button. We need to redirect them to the
          // reset password B2C custom policy.
          msalInstance.loginRedirect({
            scopes: ["openid"],
            authority: b2cPolicies.authorities.forgotPassword.authority,
          });

          setDisplayLoadingScreen(true);
        }
      }
    });

    return () => {
      if (callbackId) {
        msalInstance.removeEventCallback(callbackId);
      }
    };
  }, []);

  // The Branded Onboarding flow needs to redirect the user to the original site after logout
  const handleSignOut = React.useCallback((logoutUriOverride?: string) => {
    logoutUriOverride = convertUriToAcsissRedirectUri(logoutUriOverride);
    msalInstance
      .logoutRedirect({ postLogoutRedirectUri: logoutUriOverride })
      .then(() => {
        successToast({
          title: "Logged out",
        });
      })
      .catch((e: Error) => {
        errorToast({
          title: "Failed to logout",
          description: e.message,
        });
      });
  }, []);

  const handleGetToken = React.useCallback(async () => {
    try {
      const accounts = msalInstance.getAllAccounts();
      const account = accounts[0];
      if (account) {
        return await getTokenFromAccount(account, msalInstance);
      }

      throw new Error("No account to get token from");
    } catch (e) {
      console.error("Could not get token", e);
      errorToast({
        title: "Something went wrong",
      });
      throw e;
    }
  }, []);

  const handleRequestProfileUpdate = React.useCallback(() => {
    // Not sure why this type is failing but profile updating works
    // in the sample code they logged out the user after updating the profile
    // @ts-ignore
    msalInstance.loginRedirect(b2cPolicies.authorities.editProfile);

    // could also use a popup
  }, []);

  if (displayLoadingScreen) {
    return <FullPageLoadingSpinner />;
  }

  const MINUTES = 30;
  const INACTIVE_TIMEOUT = MINUTES * 60 * 1000;

  const canManageCompany = ["Manager", "Administrator"].includes(
    user?.role ?? ""
  );

  if (isUserNotEnabledWhenBlockingNewSignUps) {
    return (
      <Center h="100vh">
        <Fade in>
          <DisabledResult
            disabledAccountType={"blockingNewSignUps"}
            onLogout={handleSignOut}
          />
        </Fade>
      </Center>
    );
  }

  if (isCompanyDisabled || isUserDisabled) {
    return (
      <Center h="100vh">
        <Fade in>
          <DisabledResult
            disabledAccountType={isUserDisabled ? "user" : "company"}
            onLogout={handleSignOut}
          />
        </Fade>
      </Center>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        logout: handleSignOut,
        getToken: handleGetToken,
        onRequestProfileUpdate: handleRequestProfileUpdate,
        canManageCompany,
        loadUser: handleLoadUser,
        disableAutoLogin: handleDisableAutoLogin,
        onUserUpdated: setUser,
      }}
    >
      <>
        {children}
        <InactiveLogoutTimer
          debug={false}
          timeout={INACTIVE_TIMEOUT}
          isAuthenticated={isAuthenticated}
          onLogout={() => msalInstance.logoutRedirect()}
          promptTimeoutSeconds={30}
        />
      </>
    </AuthContext.Provider>
  );
};
