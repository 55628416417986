// Replace both plus signs, and url encoded plus signs with a url encoded space
export function replacePlusSigns(uri: string) {
  return uri.replaceAll("+", "%20").replaceAll("%2B", "%20");
}

export function convertUriToAcsissRedirectUri(redirectUri?: string) {
  if (!redirectUri) return undefined;

  return (
    window.location.origin +
    "/logout-redirect" +
    "?" +
    "acsiss-redirect=" +
    encodeURIComponent(redirectUri)
  );
}
