import "@fontsource/raleway/500.css";
import "@fontsource/raleway/700.css";
import React from "react";
import { App } from "./App";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ErrorBoundary } from "../../../libs/shared-ui/src/main";
import {
  AppChakraProvider,
  trustedAdvisorTheme,
} from "../../../libs/shared-ui/src/main";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
import { MsalProvider } from "@azure/msal-react";

declare global {
  interface Window {
    appConfig: {
      instrumentationKey?: string;
      b2cName: string;
      b2cHostname: string;
      b2cWebClientClientId: string;
      b2cWebApiClientId: string;
      googleDriveClientId: string;
      version: string;
      blockNewSignUpsFeatureFlag: boolean;
      isNonProd: boolean;
      isSandBox: boolean;
      oneDriveClientId: string;
      b2cSignUpSignInPolicy: string;
      b2cPasswordResetPolicy: string;
      b2cEditProfilePolicy: string;
      myDataApiHostName: string;
    };
  }
}

const fetchConfig = async (): Promise<void> => {
  const response = await fetch("/api/v1/config");
  window.appConfig = { ...window.appConfig, ...(await response.json()) };
};

(async () => {
  await fetchConfig();

  const { instrumentationKey } = window.appConfig || {};
  if (process.env.NODE_ENV === "production" && instrumentationKey) {
    const reactPlugin = new ReactPlugin();
    const appInsights = new ApplicationInsights({
      config: {
        instrumentationKey,
        enableAutoRouteTracking: true,
        // @ts-ignore
        extensions: [reactPlugin],
      },
    });
    appInsights.loadAppInsights();
  }

  const queryClient = new QueryClient();

  const container = document.getElementById("root")!;
  const root = createRoot(container);

  /**

   MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
   For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
   */
  const msalInstance = new PublicClientApplication(msalConfig);

  root.render(
    <AppChakraProvider theme={trustedAdvisorTheme}>
      <ErrorBoundary
        onRetryLogin={() => {
          msalInstance.loginRedirect();
        }}
      >
        <MsalProvider instance={msalInstance}>
          <QueryClientProvider client={queryClient}>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </QueryClientProvider>
        </MsalProvider>
      </ErrorBoundary>
    </AppChakraProvider>
  );
})();
