import { PaginationState, SortingState } from "@tanstack/react-table";
import { useQueryClient, UseQueryOptions } from "@tanstack/react-query";
import { ApiError } from "../api/api";
import { Account } from "../api/types/Account";
import { CustomerExport } from "../api/types/CustomerExport";
import { PagedResult } from "../api/types/PagedResult";
import { useApiGet, useApiMutation } from "../api/useApi";
import { useCompany } from "../company/useCompany";
import { EXPORT_LOCATIONS_CACHE_KEY } from "../pages/export-locations/exportLocationQueries";
import { AddCustomNameRequest, Customer } from "./types";
import { useTableFilters, useToast } from "acsiss-ui";
import { useAuth } from "../auth/useAuth";
import { makeQueryString } from "../api/utils/makeQueryString";
import { useEffect, useMemo, useState } from "react";

export type PagedCustomers = PagedResult<Customer>;

export const CUSTOMERS_CACHE_KEY = "customers";
const CUSTOMER_ACCOUNTS_CACHE_KEY = "customerAccounts";
const CUSTOMER_EXPORTS_CACHE_KEY = "customerExports";
const INVITATION_TO_CUSTOMER_CACHE_KEY = "invitationToCustomer";

export const useGetInvitedCustomers = () => {
  const { company } = useCompany();

  const enabled = Boolean(company?.id);

  return useApiGet<InvitedCustomer[]>({
    url: `/api/v1/company/${company?.id}/invitation-to-customer`,
    options: {
      queryKey: [INVITATION_TO_CUSTOMER_CACHE_KEY],
      enabled,
      keepPreviousData: true,
    },
  });
};

interface CustomerFilters {
  pagination: PaginationState;
  searchText: string;
  newOrUpdated?: boolean;
  sorting: SortingState;
}

export const useGetCustomers = (
  { pagination, searchText, newOrUpdated, sorting }: CustomerFilters,
  options?: UseQueryOptions<PagedCustomers, ApiError>
) => {
  const { company } = useCompany();

  const enabled =
    typeof options?.enabled !== "undefined"
      ? options.enabled && Boolean(company?.id)
      : Boolean(company?.id);

  const queryString = makeQueryString(
    {
      sorting,
      pagination,
      searchText,
    },
    {
      newOrUpdated,
    }
  );

  return useApiGet<PagedCustomers>({
    url: `/api/v1/company/${company?.id}/customer${queryString}`,
    options: {
      queryKey: [
        CUSTOMERS_CACHE_KEY,
        pagination,
        searchText,
        newOrUpdated,
        sorting,
      ],
      enabled,
      keepPreviousData: true,
    },
  });
};

export const useGetCustomer = (id: string) => {
  const { company } = useCompany();

  return useApiGet<Customer>({
    url: `/api/v1/company/${company?.id}/customer/${id}`,
    options: {
      queryKey: [CUSTOMERS_CACHE_KEY, id],
      enabled: Boolean(company?.id && id),
    },
  });
};

export const useGetCustomerAccounts = (
  customerId?: string,
  options?: UseQueryOptions<Account[], ApiError>,
  shareStatus: "Accepted" | "Requested" | "Declined" = "Accepted",
  startDate: Date = new Date("2017-01-01"),
  endDate: Date = new Date("2030")
) => {
  const urlAccountPath = `/customer/${customerId}/accounts`;
  return getCustomerAccounts(
    urlAccountPath,
    customerId,
    options,
    shareStatus,
    startDate,
    endDate
  );
};

export function useGetBankStatementsAccounts(
  groupCodesToShow: string[],
  customerId?: string,
  startDate: Date = new Date("2017-01-01"),
  endDate: Date = new Date("2030")
) {
  // Determines whether we filter by a ClientId, or by one or more Group Codes (this is client agnostic)
  const [filterBy, setFilterBy] = useState<"client" | "group-code">("client");

  const {
    accounts: accountsFilteredByClient,
    isLoading: areClientAccountsLoading,
  } = useGetBankStatementAccountsByClient(
    customerId,
    {},
    startDate ?? undefined,
    endDate ?? undefined
  );

  const {
    accounts: accountsFilteredByGroupCode,
    isLoading: areGroupCodeAccountsLoading,
  } = useGetBankStatementAccountsByGroupCode(
    groupCodesToShow,
    {},
    startDate ?? undefined,
    endDate ?? undefined
  );

  const accounts =
    filterBy == "client"
      ? accountsFilteredByClient
      : accountsFilteredByGroupCode;
  const isLoading = areClientAccountsLoading || areGroupCodeAccountsLoading;

  return { accounts, isLoading, filterBy, setFilterBy };
}

const useGetBankStatementAccountsByClient = (
  customerId?: string,
  options?: UseQueryOptions<Account[], ApiError>,
  startDate: Date = new Date("2017-01-01"),
  endDate: Date = new Date("2030")
) => {
  const urlAccountPath = `/customer/${customerId}/accounts/bank-statement`;
  return getCustomerAccounts(
    urlAccountPath,
    customerId,
    options,
    "Accepted",
    startDate,
    endDate
  );
};
const useGetBankStatementAccountsByGroupCode = (
  groupCodes: string[],
  options?: UseQueryOptions<Account[], ApiError>,
  startDate: Date = new Date("2017-01-01"),
  endDate: Date = new Date("2030")
) => {
  const urlAccountPath = "/accounts/bank-statement-with-group-code-filter";
  return getCustomerAccounts(
    urlAccountPath,
    null, // This won't ever be populated in this usage, and null (as opposed to undefined) here represents that we're never going to populate this
    options,
    "Accepted",
    startDate,
    endDate,
    groupCodes
  );
};

const getCustomerAccounts = (
  urlAccountPath: string,
  // If a null option was provided, the consumer of this function doesnt actually want to wait until customerId is populated
  // undefined represents that the value is not yet populated, but is expected to be
  customerId?: string | null,
  options?: UseQueryOptions<Account[], ApiError>,
  shareStatus: "Accepted" | "Requested" | "Declined" = "Accepted",
  startDate: Date = new Date("2017-01-01"),
  endDate: Date = new Date("2030"),
  groupCodes?: string[]
) => {
  const { company } = useCompany();
  const { sorting, sortingProps } = useTableFilters({
    defaultSorting: [
      {
        id: "bankName",
        desc: false,
      },
    ],
  });

  const queryString = makeQueryString({
    sorting,
  });

  const enabled =
    typeof options?.enabled !== "undefined"
      ? options.enabled && Boolean(company?.id && customerId !== undefined)
      : Boolean(company?.id && customerId !== undefined);

  const localStartDate = new Date(
    startDate.getTime() - startDate.getTimezoneOffset() * 60000
  ).toISOString();
  const localEndDate = new Date(
    endDate.getTime() - endDate.getTimezoneOffset() * 60000
  ).toISOString();

  let groupCodeListQueryParam = "";
  if (Boolean(groupCodes)) {
    for (const groupCode of groupCodes!) {
      groupCodeListQueryParam += `&groupCodes=${groupCode}`;
    }
  }

  const {
    data = [],
    isLoading,
    refetch,
  } = useApiGet<Account[]>({
    url:
      `/api/v1/company/${company?.id}` +
      `${urlAccountPath}` +
      `${queryString}` +
      `&startDate=${localStartDate}&endDate=${localEndDate}
       ${groupCodeListQueryParam}`,

    options: {
      queryKey: [
        CUSTOMER_ACCOUNTS_CACHE_KEY,
        shareStatus,
        sorting,
        customerId,
        localStartDate,
        localEndDate,
        groupCodeListQueryParam,
      ],
      ...options,
      enabled: enabled,
    },
  });

  // We memoize this filtered set of accounts, this is to prevent infinite re-render loops on pages that use this hook
  const accounts = useMemo(() => {
    return data.filter((d) => d.shareStatus === shareStatus);
  }, [data, shareStatus]);

  return {
    isLoading,
    accounts,
    refetch,
    sortingProps,
  };
};

export const useGetCustomerAccount = (
  { customerId, accountId }: { customerId: string; accountId: string },
  options?: UseQueryOptions<Account, ApiError>
) => {
  const { company } = useCompany();

  const enabled = Boolean(company?.id && customerId && accountId);

  // This is a temporary hack, just while the frontend for the bank statements is being developed
  let startDateUtc = new Date("2017-01-01").toISOString();
  let endDateUtc = new Date("2030").toISOString();

  const {
    data: account,
    isLoading,
    refetch,
  } = useApiGet<Account>({
    url: `/api/v1/company/${company?.id}/customer/${customerId}/accounts/${accountId}?startDate=${startDateUtc}&endDate=${endDateUtc}`,
    options: {
      queryKey: ["customerAccounts", customerId, accountId],
      ...options,
      enabled,
    },
  });

  return {
    isLoading,
    account,
    refetch,
  };
};

type PagedTransaction = PagedResult<Transaction>;

export const useGetCustomerAccountTransactions = (
  {
    customerId,
    accountId,
    pagination,
    searchText,
    sorting,
  }: {
    customerId: string;
    accountId: string;
    pagination: PaginationState;
    searchText: string;
    sorting: SortingState;
  },
  options?: UseQueryOptions<PagedTransaction, ApiError>
) => {
  const { company } = useCompany();

  const enabled =
    typeof options?.enabled !== "undefined"
      ? options.enabled && Boolean(company?.id && customerId && accountId)
      : Boolean(company?.id && customerId && accountId);

  const queryString = makeQueryString({
    sorting,
    searchText,
    pagination,
  });

  return useApiGet<PagedTransaction>({
    url: `/api/v1/company/${company?.id}/customer/${customerId}/accounts/${accountId}/transactions${queryString}`,
    options: {
      queryKey: [
        "accounts",
        accountId,
        customerId,
        searchText,
        pagination,
        sorting,
      ],
      ...options,
      enabled,
    },
  });
};

export const useDeclineCustomerAccount = () => {
  const mutation = useApiMutation();
  const { company } = useCompany();
  const queryClient = useQueryClient();

  const handleMutate = (
    customerId: string,
    accountId: string,
    onSuccess?: () => void
  ) => {
    const url = `/api/v1/company/${company?.id}/customer/${customerId}/accounts/${accountId}`;
    mutation.mutate(
      {
        url,
        method: "DELETE",
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([
            CUSTOMER_ACCOUNTS_CACHE_KEY,
            customerId,
          ]);

          queryClient.invalidateQueries([
            CUSTOMER_EXPORTS_CACHE_KEY,
            customerId,
          ]);

          if (onSuccess) onSuccess();
        },
      }
    );
  };

  return { mutation, onMutate: handleMutate };
};

export const useAcceptCustomerAccount = () => {
  const mutation = useApiMutation();
  const { company } = useCompany();
  const queryClient = useQueryClient();

  const handleMutate = (
    {
      customerId,
      account,
    }: {
      customerId: string;
      account: Account;
    },
    onSuccess?: () => void
  ) => {
    const url = `/api/v1/company/${company?.id}/customer/${customerId}/accounts/${account.id}`;

    const input: Account = {
      ...account,
      shareStatus: "Accepted",
    };

    mutation.mutate(
      {
        url,
        input,
        method: "PUT",
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([
            CUSTOMER_ACCOUNTS_CACHE_KEY,
            customerId,
          ]);

          queryClient.invalidateQueries([
            CUSTOMER_EXPORTS_CACHE_KEY,
            customerId,
          ]);

          if (onSuccess) onSuccess();
        },
      }
    );
  };

  return { mutation, onMutate: handleMutate };
};

export const useAddCustomNameToCustomerAccount = () => {
  const mutation = useApiMutation();
  const { company } = useCompany();
  const { successToast } = useToast();

  const handleMutate = (
    {
      customerId,
      accountId,
      customName,
    }: {
      customerId: string;
      accountId: string;
      customName: string;
    },
    onSuccess?: () => void
  ) => {
    const url = `/api/v1/company/${company?.id}/customer/${customerId}/accounts/${accountId}/custom-name`;

    const input: AddCustomNameRequest = {
      customName: customName,
    };

    mutation.mutate(
      {
        url,
        input,
        method: "PUT",
      },
      {
        onSuccess: () => {
          successToast({
            title: `Name ${
              !!input.customName ? "Updated" : "Deleted"
            } Sucessfully`,
          });
          if (onSuccess) onSuccess();
        },
      }
    );
  };

  return { mutation, onMutate: handleMutate };
};

export const useDeleteCustomer = () => {
  const mutation = useApiMutation();
  const { company } = useCompany();
  const queryClient = useQueryClient();

  const handleMutate = (customerId: string, onSuccess?: () => void) => {
    const url = `/api/v1/company/${company?.id}/customer/${customerId}`;
    mutation.mutate(
      {
        url,
        method: "DELETE",
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([CUSTOMERS_CACHE_KEY]);
          if (onSuccess) onSuccess();
        },
      }
    );
  };

  return { mutation, onMutate: handleMutate };
};

export const updateCustomerExport = () => {
  const mutation = useApiMutation();
  const { company } = useCompany();
  const queryClient = useQueryClient();

  const handleMutate = (input: CustomerExport, onSuccess: () => void) => {
    const url = `/api/v1/company/${company?.id}/export/${input.id}`;

    mutation.mutate(
      {
        url,
        input: {
          ...input,
          companyId: company!.id,
        },
        method: "PUT",
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([
            CUSTOMER_EXPORTS_CACHE_KEY,
            input.customerId,
          ]);
          queryClient.invalidateQueries([
            EXPORT_LOCATIONS_CACHE_KEY,
            input.exportLocationId,
          ]);

          queryClient.invalidateQueries([
            CUSTOMERS_CACHE_KEY,
            input.customerId,
          ]);

          onSuccess();
        },
      }
    );
  };

  return { mutation, onMutate: handleMutate };
};

export const useGetCustomerExports = (
  customerId: string,
  options?: UseQueryOptions<CustomerExport[], ApiError>
) => {
  const { company } = useCompany();

  const enabled =
    typeof options?.enabled !== "undefined"
      ? options.enabled && Boolean(company?.id)
      : Boolean(company?.id);

  return useApiGet<CustomerExport[]>({
    url: `/api/v1/company/${company?.id}/export?customerId=${customerId}`,
    options: {
      queryKey: [CUSTOMER_EXPORTS_CACHE_KEY, customerId],
      ...options,
      enabled,
    },
  });
};

interface ExportLocationCustomersFilters {
  exportLocationId: string;
  pagination: PaginationState;
  searchText: string;
  sorting: SortingState;
}

export const useGetCustomersForExportLocation = (
  {
    pagination,
    searchText,
    exportLocationId,
    sorting,
  }: ExportLocationCustomersFilters,
  options?: UseQueryOptions<PagedCustomers, ApiError>
) => {
  const { company } = useCompany();

  const enabled =
    typeof options?.enabled !== "undefined"
      ? options.enabled && Boolean(company?.id)
      : Boolean(company?.id);

  const queryString = makeQueryString(
    {
      sorting,
      searchText,
      pagination,
    },
    {
      hasExportWithExportLocationId: exportLocationId,
    }
  );

  const url = `/api/v1/company/${company?.id}/customer${queryString}`;

  return useApiGet<PagedCustomers>({
    url,
    options: {
      queryKey: [
        EXPORT_LOCATIONS_CACHE_KEY,
        exportLocationId,
        searchText,
        pagination,
        sorting,
      ],
      ...options,
      enabled,
      keepPreviousData: true,
    },
  });
};

export const useDeleteCustomerExport = () => {
  const mutation = useApiMutation();
  const { company } = useCompany();
  const queryClient = useQueryClient();

  const handleMutate = (
    customerId: string,
    exportId: string,
    onSuccess?: () => void
  ) => {
    const url = `/api/v1/company/${company?.id}/export/${exportId}`;
    mutation.mutate(
      {
        url,
        method: "DELETE",
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([
            CUSTOMER_EXPORTS_CACHE_KEY,
            customerId,
          ]);
          if (onSuccess) onSuccess();
        },
      }
    );
  };

  return { mutation, onMutate: handleMutate };
};

interface ForceUploadCustomerExportInput {
  customerExport: CustomerExport;
  startDateTimeLocal: string;
  endDateTimeLocal: string;
}

export const useForceUploadCustomerExport = () => {
  const mutation = useApiMutation();
  const { company } = useCompany();

  const handleMutate = (
    input: ForceUploadCustomerExportInput,
    onSuccess?: () => void
  ) => {
    const url = `/api/v1/company/${company?.id}/export/${input.customerExport.id}/force-upload`;
    mutation.mutate(
      {
        url,
        method: "POST",
        input,
      },
      {
        onSuccess: () => {
          if (onSuccess) onSuccess();
        },
      }
    );
  };

  return { mutation, onMutate: handleMutate };
};

export const updateInviteToCustomer = () => {
  const mutation = useApiMutation();
  const { company } = useCompany();
  const { user } = useAuth();
  const queryClient = useQueryClient();

  const handleMutate = (input: InvitationToCustomer, onSuccess: () => void) => {
    const url = `/api/v1/company/${company?.id}/invitation-to-customer/${input.id}`;
    input.companyId = company!.id;
    input.allocatedAdvisorId = user!.id;

    mutation.mutate(
      { url, input, method: "PUT" },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([INVITATION_TO_CUSTOMER_CACHE_KEY]);
          onSuccess();
        },
      }
    );
  };
  return { mutation, onMutate: handleMutate };
};

export const sendCustomerInvites = () => {
  const mutation = useApiMutation();
  const { company } = useCompany();
  const queryClient = useQueryClient();

  const handleMutate = (
    invitations: InvitationToCustomer[],
    onSuccess: () => void
  ) => {
    const url = `/api/v1/company/${company?.id}/invitation-to-customer`;

    mutation.mutate(
      { url, input: invitations, method: "POST" },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([INVITATION_TO_CUSTOMER_CACHE_KEY]);
          onSuccess();
        },
      }
    );
  };
  return { mutation, onMutate: handleMutate };
};

export interface BulkCustomerToInvite {
  id: string;
  customerName: string;
  customerEmailAddress: string;
  inviteStatus: "INVITE" | "RESEND" | "DELETE";
}

export interface BulkInvitesToCustomers {
  companyId: string;
  allocatedAdvisorId: string;
  invitations: BulkCustomerToInvite[];
  customMessage: string;
}

export const sendBulkCustomerInvites = () => {
  const mutation = useApiMutation();
  const { company } = useCompany();
  const queryClient = useQueryClient();

  const handleMutate = (
    bulkInvite: BulkInvitesToCustomers,
    onSuccess: () => void
  ) => {
    const url = `/api/v1/company/${company?.id}/invitation-to-customer/bulk-invite`;

    mutation.mutate(
      { url, input: bulkInvite, method: "POST" },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([INVITATION_TO_CUSTOMER_CACHE_KEY]);
          onSuccess();
        },
      }
    );
  };
  return { mutation, onMutate: handleMutate };
};

export const useGetAccountBalances = ({
  customerId,
  accountId,
  pagination,
  sorting,
  searchText,
}: {
  customerId: string;
  accountId: string;
  sorting: SortingState;
  pagination: PaginationState;
  searchText: string;
}) => {
  const queryString = makeQueryString({
    pagination,
    sorting,
    searchText,
  });

  const { company } = useCompany();
  const enabled = Boolean(company?.id);
  return useApiGet<PagedResult<AccountBalance>>({
    url: `/api/v1/company/${company?.id}/customer/${customerId}/accounts/${accountId}/balances${queryString}`,
    options: {
      queryKey: ["accounts", accountId, customerId, "balances", queryString],
      enabled,
    },
  });
};

export const useDeclineCustomerInvite = () => {
  const mutation = useApiMutation();
  const { company } = useCompany();
  const queryClient = useQueryClient();

  const handleMutate = (inviteId: string, onSuccess?: () => void) => {
    const url = `/api/v1/company/${company?.id}/invitation-to-customer/${inviteId}`;
    mutation.mutate(
      {
        url,
        method: "DELETE",
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([INVITATION_TO_CUSTOMER_CACHE_KEY]);
          if (onSuccess) onSuccess();
        },
      }
    );
  };

  return { mutation, onMutate: handleMutate };
};

export const useAcceptCustomerInvite = () => {
  const mutation = useApiMutation();
  const queryClient = useQueryClient();
  const { successToast } = useToast();

  const handleMutate = (invitationId: string, onSuccess?: () => void) => {
    console.log("Accepting Invite", invitationId);
    const url = `/api/v1/invitation-from-customer/${invitationId}/accept`;
    mutation.mutate(
      {
        url,
        method: "PUT",
      },
      {
        onSuccess: () => {
          successToast({
            title: "Invitation accepted",
          });

          queryClient.invalidateQueries([CUSTOMERS_CACHE_KEY]);
          if (onSuccess) {
            onSuccess();
          }
        },
      }
    );
  };

  return { mutation, onMutate: handleMutate };
};

export type GetGroupCodesForCompanyAccountsResponse = {
  groupCodes: string[];
};
export function useGetGroupCodesForCompanyCustomerAccounts() {
  const { company } = useCompany();

  return useApiGet<GetGroupCodesForCompanyAccountsResponse>({
    url: `/api/v1/company/${company?.id}/accounts/group-codes`,
    options: {
      queryKey: ["COMPANY_GROUP_CODES", company?.id],
      enabled: Boolean(company?.id),
    },
  });
}
